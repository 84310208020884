export default {
  planInfo1: [
    {
      first: true,
      second: true,
      third: true,
      fourth: true,
      text: "Platforms: Web, WordPress, Plesk, Joomla, Drupal",
    },
    {
      first: true,
      second: true,
      third: true,
      fourth: true,
      text: "Antivirus: Automatic and on" + "\n" + "demand malware scanning",
    },
    {
      first: true,
      second: true,
      third: true,
      fourth: true,
      text: "Firewall",
    },
    {
      first: true,
      second: true,
      third: true,
      fourth: true,
      text: "IP black/whitelisting",
    },
    {
      first: true,
      second: true,
      third: true,
      fourth: true,
      text: "URL black/whitelisting",
    },
    {
      first: true,
      second: true,
      third: true,
      fourth: true,
      text: "SSL monitoring",
    },
    {
      first: true,
      second: true,
      third: true,
      fourth: true,
      text: "Open ports scanner",
    },
    {
      first: true,
      second: true,
      third: true,
      fourth: true,
      text: "Open path scanner",
    },
    {
      first: true,
      second: true,
      third: true,
      fourth: true,
      text: "Vulnerabilities (CVE) scanner",
    },
    {
      first: true,
      second: true,
      third: true,
      fourth: true,
      text: "Technology scanner",
    },
    {
      first: true,
      second: true,
      third: true,
      fourth: true,
      text: "Blacklist monitoring",
    },
    {
      first: true,
      second: true,
      third: true,
      fourth: true,
      text: "Security risks analysis",
    },
    {
      first: true,
      second: true,
      third: true,
      fourth: true,
      text: "PDF reports",
    },
    {
      first: "1",
      second: "Up to 3",
      third: "Up to 50",
      fourth: "Unlimited",
      text: "Websites",
    },
    {
      first: false,
      second: false,
      third: "Up to 3",
      fourth: "Up to 50",
      text: "Teammates",
    },
    {
      first: false,
      second: false,
      third: true,
      fourth: true,
      text: "Global Defense Network",
    },
    {
      first: false,
      second: false,
      third: true,
      fourth: true,
      text: "Central monitoring dashboard",
    },
    {
      first: false,
      second: false,
      third: true,
      fourth: true,
      text: "Whitelabel PDF reports",
    },
    {
      first: false,
      second: false,
      third: true,
      fourth: true,
      text: "De-blacklisting",
    },
    {
      first: false,
      second: false,
      third: false,
      fourth: true,
      text: "Security Incident investigations",
    },
  ],
}
