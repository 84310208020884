import React, { useState, useEffect } from "react"
import classNames from "classnames"
import "./styles.css"
import Switch from "../../components/switch"
import PricingCard from "../../components/card"
import SignUpForm from "../../components/sign-up-form"
import ReactModal from "react-modal"
import { SignUpModalStyles } from "../../styles/modal"
import plan from "./plan"
import CheckedIcon from "../../images/checked.svg"
import { useTranslation } from "../../utils"
import Popup from "reactjs-popup"
import { useAnalytics } from "../../hooks/useTracking"
import { useAuthCheck } from "../../hooks/useAuthCheck"
import { toast } from "react-toastify"
import { CONFIG } from "../../config"

const getCheck = (item, tab) => {
  return {
    1: item.first,
    2: item.second,
    3: item.third,
    4: item.fourth,
  }[tab]
}

const singleIncluded = [
  "1 Site",
  "Antivirus",
  "Web application Firewall",
  "SSL monitoring",
  "Open ports & path scanner",
  "Availability monitoring",
  "Wordpress, Plesk, Joomla, Drupal",
]

const standardIncluded = [
  "3 Site",
  "Everything in Single",
  "Website security scoring",
  "Blacklist monitoring",
  "Technology & Vulnerability scanner",
  "IP Reputation",
  "Server Resources monitoring",
]

const professionalIncluded = [
  "Up to 50 websites",
  "Everything in Standard",
  "Central monitoringd dashboard",
  "Whitelabel PDF reports",
  "Up to 3 team members",
  "De-blacklisting",
  "High priority event support",
]
const tariffQuery = `
    query {
      auth {
        viewer {
          tariff {
            tariffActive
            name
          }
        }
    }
  }
`
const PricingTableSection = () => {
  const { t, locale } = useTranslation()
  const [cycle, setCycle] = useState("monthly")
  const [tab, setTab] = useState(1)
  const [isModalOpen, setModalOpen] = useState(false)
  const [pickedTariff, setPickedTariff] = useState(null)
  const analytics = useAnalytics()
  const { authed } = useAuthCheck()
  const [authTariff, setAuthTariff] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (authed && !loading) {
      try {
        const token = JSON.parse(localStorage.getItem("tokenData"))?.value
        const fetchTariff = async () => {
          const response = await fetch(CONFIG?.API_URL + "/graphql", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              query: tariffQuery,
            }),
          })
          const result = await response.json()
          setAuthTariff(result?.data?.auth?.viewer?.tariff)
        }
        fetchTariff()
      } catch (err) {
        toast.error(t(err.message))
        if (
          err.message === "inavlid token" ||
          err.message === "INVALID_TOKEN"
        ) {
          localStorage.removeItem("tokenData")
        }
      }
    }
  }, [authed, loading])

  const changeTariff = async name => {
    const query = `mutation($name: String, $cycle: TariffCycle) {
                      auth {
                        tariffs {
                          change(name: $name, cycle: $cycle) 
                        }
                      }
                    }`

    const queryData = {
      query,
      variables: {
        name,
        cycle: cycle.toUpperCase(),
      },
    }
    try {
      setLoading(true)
      const token = JSON.parse(localStorage.getItem("tokenData"))?.value
      const response = await fetch(CONFIG?.API_URL + "/graphql", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          ...queryData,
        }),
      })
      const result = await response.json()
      if (result?.errors?.length) throw new Error(result.errors[0].message)
    } catch (err) {
      toast.error(t(err.message))
      if (err.message === "inavlid token" || err.message === "INVALID_TOKEN") {
        localStorage.removeItem("tokenData")
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <section className="pricing-table-section" id="pricing-table-section">
      <div className="container">
        <div className="pricing-table-section__badge pricing-page__badge">
          {t("Pricing")}
        </div>
        <div className="pricing-table-section__title">
          <h1>{t("Simple & affordable pricing just for you")}</h1>

          <Switch theme="gray" cycle={cycle} onChange={setCycle} />
        </div>
        <div className="pricing-table-section__switcher" lang={locale}>
          <div
            onClick={() => setTab(1)}
            className={classNames("pricing-table-section__switcher-button", {
              "pricing-table-section__switcher-button--active": tab === 1,
            })}
          >
            {t("Single")}
          </div>
          <div
            onClick={() => setTab(2)}
            className={classNames("pricing-table-section__switcher-button", {
              "pricing-table-section__switcher-button--active": tab === 2,
            })}
          >
            {t("Standard")}
          </div>
          <div
            onClick={() => setTab(3)}
            className={classNames("pricing-table-section__switcher-button", {
              "pricing-table-section__switcher-button--active": tab === 3,
            })}
          >
            {t("Professional")}
          </div>
        </div>
        <div className="pricing-table-section__tariffs">
          <div
            className={classNames("wt-flex-1", {
              "display-xs-none display-sm-none display-md-none": tab !== 1,
            })}
          >
            <PricingCard
              authed={authed}
              pickedTariff={pickedTariff}
              tariff="SINGLE_V4"
              active={
                authTariff?.tariffActive && authTariff?.name === "SINGLE_V4"
              }
              loading={loading}
              badgeText="1 Site"
              title={t("Single")}
              responsive={false}
              description={t("The plan is perfect for a single website")}
              cost={cycle === "monthly" ? "$8" : "$7"}
              quantity={`${t("per site")} / ${
                cycle === "monthly" ? t("month") : t("month")
              }`}
              backQuantity={"/ per-site"}
              buttonText={t("Sign up now")}
              tier="Single"
              included={singleIncluded}
              withLink={true}
              cycle={cycle}
              trigger={
                authTariff?.tariffActive && authTariff?.name !== "SINGLE_V4"
                  ? () => {
                      setPickedTariff("SINGLE_V4")
                      changeTariff("SINGLE_V4")
                      analytics.changeTariff("SINGLE_V4")
                    }
                  : () => {
                      analytics.signUp(cycle, pickedTariff)
                      setModalOpen(true)
                      setPickedTariff("SINGLE_V4")
                    }
              }
            />
          </div>
          <div
            className={classNames("wt-flex-1", {
              "display-xs-none display-sm-none display-md-none": tab !== 2,
            })}
          >
            <PricingCard
              authed={authed}
              pickedTariff={pickedTariff}
              tariff="STANDARD_V4"
              active={
                authTariff?.tariffActive && authTariff?.name === "STANDARD_V4"
              }
              loading={loading}
              badge="Up to 3 Sites"
              badgeText={t("Most popular 🔥")}
              yellow={true}
              title={t("Standard")}
              mostPopular
              responsive={false}
              description={t("The plan is perfect for freelancers")}
              cost={cycle === "monthly" ? "10$" : "$8"}
              quantity={`${t("per site")} / ${
                cycle === "monthly" ? t("month") : t("month")
              }`}
              backQuantity={"/ per-site"}
              buttonText={t("Sign up now")}
              tier="Standard"
              included={standardIncluded}
              withLink={true}
              cycle={cycle}
              trigger={
                authTariff?.tariffActive && authTariff?.name !== "STANDARD_V4"
                  ? () => {
                      setPickedTariff("STANDARD_V4")
                      changeTariff("STANDARD_V4")
                      analytics.changeTariff("STANDARD_V4")
                    }
                  : () => {
                      analytics.signUp(cycle, pickedTariff)
                      setModalOpen(true)
                      setPickedTariff("STANDARD_V4")
                    }
              }
            />
          </div>
          <div
            className={classNames(
              "wt-flex-1 pricing-table-section__card-container--last",
              {
                "display-xs-none display-sm-none display-md-none": tab !== 3,
              }
            )}
          >
            <PricingCard
              authed={authed}
              tariff="PROFESSIONAL_V4"
              pickedTariff={pickedTariff}
              active={
                authTariff?.tariffActive &&
                authTariff?.name === "PROFESSIONAL_V4"
              }
              loading={loading}
              badgeText={t("Up to 50 sites")}
              tier="Professional"
              included={professionalIncluded}
              title={t("Professional")}
              responsive={false}
              backQuantity={"/ per-site"}
              description={t("The plan is perfect for agencies")}
              cost={cycle === "monthly" ? "$18" : "$16"}
              quantity={`${t("per site")} / ${
                cycle === "monthly" ? t("month") : t("month")
              }`}
              buttonText={t("Sign up now")}
              withLink={true}
              cycle={cycle}
              trigger={
                authTariff?.tariffActive &&
                authTariff?.name !== "PROFESSIONAL_V4"
                  ? () => {
                      setPickedTariff("PROFESSIONAL_V4")
                      changeTariff("PROFESSIONAL_V4")
                      analytics.changeTariff("PROFESSIONAL_V4")
                    }
                  : () => {
                      analytics.signUp(cycle, pickedTariff)
                      setModalOpen(true)
                      setPickedTariff("PROFESSIONAL_V4")
                    }
              }
            />
          </div>
        </div>
        <table className="pricing-table-section__table" id="anchor">
          <tbody>
            <TableHeader title={t("Feature")} />
            {plan.planInfo1.map((item, index) => (
              <TableRow tab={tab} item={item} key={index} />
            ))}
          </tbody>
        </table>
        {/*<div className="wt-flex wt-flex-center">*/}
        {/*  <div className="pricing-table-section__more" onClick={toggle}>*/}
        {/*    <span>{showMore ? t("Hide features") : t("More features")}</span>*/}
        {/*    <img*/}
        {/*      className={classNames("wt-margin-left-10", {*/}
        {/*        "pricing-table-section__more--rotate": showMore,*/}
        {/*      })}*/}
        {/*      src={ArrowIcon}*/}
        {/*      alt="arrow"*/}
        {/*    />*/}
        {/*  </div>*/}
        {/*</div>*/}
        <div className="pricing-mobile-table">
          {plan.planInfo1.map((item, index) => (
            <div key={index} className="pricing-mobile-table__row">
              <span>{item.text}</span>
              <div className="pricing-mobile-table__row-status">
                {getCheck(item, tab) && <img src={CheckedIcon} alt="checked" />}
              </div>
            </div>
          ))}
        </div>
      </div>
      <ReactModal
        isOpen={isModalOpen}
        style={SignUpModalStyles}
        onRequestClose={() => setModalOpen(false)}
      >
        <SignUpForm tariff={pickedTariff} cycle={cycle.toUpperCase()} />
      </ReactModal>
    </section>
  )
}

const TableHeader = ({ title }) => {
  const { t } = useTranslation()
  return (
    <tr>
      <td>
        <div className="wt-text wt-text--size-400 wt-text--blue wt-text--bold">
          {title}
        </div>
      </td>
      <td className="wt-text wt-text--size-400 wt-text--blue wt-text--bold">
        {t("Single")}
      </td>
      <td className="wt-text wt-text--size-400 wt-text--blue wt-text--bold">
        {t("Standard")}
      </td>
      <td className="wt-text wt-text--size-400 wt-text--blue wt-text--bold">
        {t("Professional")}
      </td>
      <td className="wt-text wt-text--size-400 wt-text--blue wt-text--bold">
        {t("Custom")}
      </td>
    </tr>
  )
}

const TableRow = ({ tab, item }) => {
  const { t } = useTranslation()
  return (
    <tr>
      <td>
        <div className="wt-flex wt-flex-between">
          {item.hint ? (
            <Popup
              on={["hover", "focus"]}
              contentStyle={{
                background: "#3D50DF",
                color: "#ffffff",
                border: "none",
                padding: "10px 15px",
                borderRadius: "5px",
              }}
              arrowStyle={{
                background: "#3D50DF",
              }}
              position={"top center"}
              trigger={
                <div className="wt-text wt-text--dark-gray wt-text--size-200 wt-pointer">
                  {t(item.text)}
                </div>
              }
            >
              <span>{t(item.hint)}</span>
            </Popup>
          ) : (
            <div className="wt-text wt-text--dark-gray wt-text.wt-text--size-300">
              {t(item.text)}
            </div>
          )}
          <div className="display-lg-none display-none">
            {tab === 1 && (
              <div>
                {typeof item.first === "string" ? (
                  <div className="wt-text wt-text--blue wt-text--size-200">
                    {t(item.first)}
                  </div>
                ) : (
                  item.first && <img src={CheckedIcon} alt="checked" />
                )}
              </div>
            )}
            {tab === 2 && (
              <div>
                {typeof item.second === "string" ? (
                  <div className="wt-text wt-text--blue wt-text--size-200">
                    {t(item.second)}
                  </div>
                ) : (
                  item.second && <img src={CheckedIcon} alt="checked" />
                )}
              </div>
            )}
            {tab === 3 && (
              <div>
                {typeof item.third === "string" ? (
                  <div className="wt-text wt-text--blue wt-text--size-200">
                    {t(item.third)}
                  </div>
                ) : (
                  item.third && <img src={CheckedIcon} alt="checked" />
                )}
              </div>
            )}
            {tab === 4 && (
              <div>
                {typeof item.fourth === "string" ? (
                  <div className="wt-text wt-text--blue wt-text--size-200">
                    {t(item.fourth)}
                  </div>
                ) : (
                  item.fourth && <img src={CheckedIcon} alt="checked" />
                )}
              </div>
            )}
          </div>
        </div>
      </td>
      <td className="display-md-none display-sm-none display-xs-none">
        {typeof item.first === "string" ? (
          <div className="wt-text wt-text--blue wt-text--size-200">
            {t(item.first)}
          </div>
        ) : (
          item.first && <img src={CheckedIcon} alt="checked" />
        )}
      </td>
      <td className="display-md-none display-sm-none display-xs-none">
        {typeof item.second === "string" ? (
          <div className="wt-text wt-text--blue wt-text--size-200">
            {t(item.second)}
          </div>
        ) : (
          item.second && <img src={CheckedIcon} alt="checked" />
        )}
      </td>
      <td className="display-md-none display-sm-none display-xs-none">
        {typeof item.third === "string" ? (
          <div className="wt-text wt-text--blue wt-text--size-200">
            {t(item.third)}
          </div>
        ) : (
          item.third && <img src={CheckedIcon} alt="checked" />
        )}
      </td>
      <td className="display-md-none display-sm-none display-xs-none">
        {typeof item.fourth === "string" ? (
          <div className="wt-text wt-text--blue wt-text--size-200">
            {t(item.fourth)}
          </div>
        ) : (
          item.fourth && <img src={CheckedIcon} alt="checked" />
        )}
      </td>
    </tr>
  )
}
export default PricingTableSection
