import React from "react"
import "./styles.css"
import classNames from "classnames"
import PropTypes from "prop-types"
import Spinner from "../../images/spinner.svg"

const PricingCard = ({
  showCostInMobile = true,
  active,
  authed,
  loading,
  pickedTariff,
  tariff,
  ...props
}) => {
  return (
    <div className="pricing-card">
      <div
        className={classNames("pricing-card__badge", {
          "pricing-card__badge--yellow": props.mostPopular,
        })}
      >
        {props.badgeText}
      </div>
      <div className="wt-flex wt-flex-between wt-flex-middle md-wt-flex-column">
        <div>
          <p className="pricing-card__tier">{props.tier}</p>
          <p className="pricing-card__description">{props.description}</p>
        </div>
        <div className="pricing-card__cost-container">
          <p className="pricing-card__cost">{props.cost}</p>
          <p className="pricing-card__period">per month</p>
        </div>
      </div>
      <div>
        <p>What's included:</p>
        <ul className="pricing-card__list">
          {props?.included?.map(item => (
            <li key={item} className="pricing-card__list-item">
              {item}
            </li>
          ))}
        </ul>
      </div>
      <div className="pricing-card__action">
        <button
          className="wt-button wt-relative"
          disabled={active || loading}
          onClick={props.trigger}
        >
          {active ? "Current plan" : authed ? "Subscribe" : "Sign Up"}
          {loading && pickedTariff === tariff && (
            <div className="wt-loader-wrapper">
              <img src={Spinner} alt="spinner" />
            </div>
          )}
        </button>
        <p className="pricing-card__trial">{props.trial}</p>
      </div>
    </div>
  )
}

PricingCard.propTypes = {
  badgeYellow: PropTypes.string,
  responsive: PropTypes.bool,
  setActive: PropTypes.func,
  active: PropTypes.bool,
  floating: PropTypes.string,
  included: PropTypes.arrayOf(PropTypes.string),
  badge: PropTypes.string.isRequired,
  yellow: PropTypes.bool,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  cost: PropTypes.string,
  quantity: PropTypes.string,
  backQuantity: PropTypes.string,
  amount: PropTypes.string,
  buttonText: PropTypes.string,
  trigger: PropTypes.func,
  trial: PropTypes.string,
  link: PropTypes.string,
  showCostInMobile: PropTypes.bool,
  withLink: PropTypes.bool,
  cycle: PropTypes.oneOf(["monthly", "yearly"]),
  badgeColor: PropTypes.string,
}

export default PricingCard
