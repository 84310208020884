import axios from "../axios"
import { toast } from "react-toastify"

export const signup = async data => {
  try {
    const query = `
        mutation ($input: RegistrationInput!) {
          guest {
              users {
                  registration(input: $input) {
                      name
                      email
                  }
              }
          }
        }`

    const queryData = {
      query,
      variables: {
        input: {
          name: data.name,
          email: data.email,
          password: data.password,
          tariffName: data.tariff,
          tariffCycle: data.cycle.toUpperCase(),
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          referralKey: data.ref_key,
          cardToken: data.cardToken,
          site: data.site
            ? {
                title: data.site,
                hostname: data.site,
                configs: {
                  scheme: data.scheme,
                  port: data.scheme === "http" ? 80 : 443,
                },
              }
            : undefined,
        },
      },
    }
    const response = await axios.post("/graphql", queryData)
    if (response.status === 200 && !response.data.errors) {
      return true
    } else {
      response.data.errors.map(item => {
        let msg = item.message
        if (item.message === "USER_ALREADY_REGISTERED") {
          msg = "User already registered"
        }
        toast(msg)
        return false
      })
    }
  } catch (e) {
    toast(e.message)
    return false
  }
}
